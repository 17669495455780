<!--
 * @Author: your name
 * @Date: 2021-12-09 18:05:59
 * @LastEditTime: 2022-01-05 14:27:51
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/views/Login/inForm.vue
-->
<template>
  <div class="login-inner">
    <div>
      <img
        width="200px"
        height="160px"
        src="../../images/ausu-login-logo.png"
      />
    </div>
    <!-- <div class="login-h1">安速货运</div> -->
    <div class="login-h2">专注于亚马逊头程运输</div>
    <div class="login-form">
      <a-form-model ref="loginForm" :model="form">
        <a-form-model-item
          prop="username"
          :rules="validate({ name: 'value', msg: '请输入账号' })"
        >
          <a-input
            size="large"
            v-model="form.username"
            allowClear
            placeholder="请输入账号"
          >
            <a-icon slot="prefix" type="user" />
          </a-input>
        </a-form-model-item>
        <a-form-model-item
          prop="password"
          :rules="validate({ name: 'value', msg: '请输入密码' })"
        >
          <a-input
            type="password"
            size="large"
            allowClear
            v-model="form.password"
            placeholder="请输入密码"
          >
            <a-icon slot="prefix" type="lock" />
          </a-input>
        </a-form-model-item>
      </a-form-model>
      <div style="margin-top: 60px; margin-bottom: 20px">
        <a-button
          size="large"
          style="width: 100%"
          type="primary"
          @click="loginSubmit"
          @keyup.enter.native="loginSubmit"
          >登 录</a-button
        >
      </div>
      <div class="footer-operation">
        <span class="login-st" @click="next">没有账号，立刻注册 ></span>
        <span @click="jumpToTrackPageHandle">物流单号查询 ></span>
      </div>
    </div>
  </div>
</template>

<script>
import { login } from "@/api/Login";
export default {
  data() {
    return {
      form: {
        // username: "zmzm",
        // password: "111111",
        username: "",
        password: "",
      },
    };
  },
  created() {
    this.keyupEnter();
  },
  methods: {
    jumpToTrackPageHandle() {
      this.$router.push({
        path: "/trackingQuery",
      });
    },
    // 绑定enter事件
    keyupEnter() {
      document.onkeydown = (e) => {
        if (e.keyCode === 13 && e.target.baseURI.match(/login/)) {
          //回车后执行搜索方法
          this.loginSubmit();
        }
      };
    },
    // 登录
    loginSubmit() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          login(this.form).then((res) => {
            this.setToken(res.data.token);
            this.setUser(this.form.username);
            // 进行授权
            // 本地重定向地址 https%3A%2F%2Fuser.ansuex.cn%2FforecastShipping
            // const redirect_uri =
            //   "https%3A%2F%2Fuser.ansuex.cn%2FforecastShipping";
            // const authorUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=ww5277411b26a535f5&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_privateinfo&agentid=1000033&state=STATE#wechat_redirect`;
            // window.parent.location.href = authorUrl;
            this.$router.push("/");
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 下一步
    next() {
      this.setLoginStep(1);
    },
  },
};
</script>

<style lang="less" scoped>
.login-inner {
  position: relative;
  width: 600px;
  margin-left: 175px;
  margin-top: 122px;
}
.login-h1 {
  font-size: 32px;
  font-weight: bold;
  color: #333333;
  margin-top: 48px;
}
.login-h2 {
  font-size: 28px;
  font-weight: 500;
  color: #333333;
}
.login-form {
  width: 400px;
  padding-top: 28px;
}
.footer-operation {
  display: flex;
  justify-content: space-between;
  > span {
    font-size: 16px;
    cursor: pointer;
  }
}
.login-st {
  font-size: 16px;
  cursor: pointer;
}
</style>
