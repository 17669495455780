<!--
 * @Author: your name
 * @Date: 2021-11-22 16:20:34
 * @LastEditTime: 2021-12-10 16:13:21
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/views/Login/index.vue
-->
<template>
    <div class="login-container">
        <div class="d1 bng"></div>
        <div class="d1">
            <inForm v-if="getLoginStep == 0"></inForm>
            <registerStepOne v-if="getLoginStep == 1" @next="regNext"></registerStepOne>
            <registerStepTwo v-if="getLoginStep == 2" :obj="formObj"></registerStepTwo>
        </div>
    </div>
</template>

<script>
    import inForm from "./inForm.vue"
    import registerStepOne from "./registerStepOne.vue"
    import registerStepTwo from "./registerStepTwo.vue"
    export default {
        name: "Login",
        components: {
            inForm,
            registerStepOne,
            registerStepTwo
        },
        data() {
            return {
                formObj: {}
            };
        },
        methods: {
            regNext(obj) {
                this.formObj = obj;
            }
        },
        created() {
            if (this.$route.query.tk) {
                this.setToken(this.$route.query.tk);
                this.setUser(this.$route.query.userName)
                this.$router.push("/");
            }

        }
    };
</script>

<style lang="less" scoped>
    .login-container {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-start;

        .d1 {
            position: relative;
            width: 50%;
            height: 100%;
        }

        .bng {
            background-image: url("../../assets/images/bg.png");
            background-repeat: no-repeat;
            background-size: cover;
        }
    }
</style>