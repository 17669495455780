<!--
 * @Author: your name
 * @Date: 2021-12-10 15:54:17
 * @LastEditTime: 2021-12-10 16:32:03
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/views/Login/registerStepTwo.vue
-->
<template>
    <div class="rg-s2-inner">
        <div class="rg-s1-t1">
            注册
        </div>
        <div class="rg-s1-t2">
            2.填写注册信息
        </div>
        <div class="rg-form">
            <a-form-model ref="ristForm" :rules="rules" :model="form">
                <a-form-model-item label="公司" prop="Company" :rules="validate({name: 'value', msg: '请输入公司'})">
                    <a-input size="large" allowClear v-model="form.Company" placeholder="公司"></a-input>
                </a-form-model-item>
                <a-form-model-item label="姓名" prop="Name" :rules="validate({name: 'value', msg: '请输入姓名'})">
                    <a-input size="large" allowClear v-model="form.Name" placeholder="姓名"></a-input>
                </a-form-model-item>
                <a-form-model-item label="密码" prop="Password">
                    <a-input size="large" type="password" allowClear v-model="form.Password" placeholder="密码"></a-input>
                </a-form-model-item>
                <a-form-model-item label="确认密码" prop="ConfirmPassword">
                    <a-input size="large" type="password" allowClear v-model="form.ConfirmPassword" placeholder="确认密码"></a-input>
                </a-form-model-item>
            </a-form-model>
            <div style="margin-top: 60px;margin-bottom: 20px">
                <a-button size="large" style="width: 100%" type="primary" @click="register">立刻注册</a-button>
            </div>
            <span class="rg-st" @click="backLogin">已有账号，直接登录></span>
        </div>
    </div>
</template>

<script>
import { regist } from "@/api/Login";
export default {
    props: {
        obj: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        let validatePass = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入密码"));
            } else {
                if (this.form.ConfirmPassword !== "") {
                    this.$refs.ristForm.validateField("ConfirmPassword");
                }
                callback();
            }
        };
        let validatePass2 = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请再次输入密码"));
            } else if (value !== this.form.Password) {
                callback(new Error("2次密码不一致"));
            } else {
                callback();
            }
        };
        return {
            form: {
                phone: "",
                code: "",
                Company: "",
                Name: "",
                Password: "",
                ConfirmPassword: "",
            },
            rules: {
                Password: [{ validator: validatePass, trigger: "change" }],
                ConfirmPassword: [{ validator: validatePass2, trigger: "change" }],
            },
        };
    },
    watch: {
        obj: {
            handler(val) {
                this.form = Object.assign(this.form, val);
            },
            immediate: true,
        },
    },
    methods: {
        backLogin() {
            this.setLoginStep(0);
        },
        register() {
            this.$refs.ristForm.validate((valid) => {
                if (valid) {
                    regist(this.form).then(() => {
                        this.setLoginStep(0);
                    });
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
    },
};
</script>

<style lang="less" scoped>
.rg-s2-inner {
    position: relative;
    width: 700px;
    margin-left: 175px;
    margin-top: 55px;
}
.rg-s1-t1 {
    font-size: 32px;
    font-weight: bold;
    color: #333333;
}
.rg-s1-t2 {
    font-size: 28px;
    font-weight: 500;
    color: #333333;
}
.rg-form {
    width: 400px;
    margin-top: 50px;
}
.rg-st {
    font-size: 16px;
    cursor: pointer;
}
</style>