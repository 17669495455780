/*
 * @Author: your name
 * @Date: 2021-12-01 16:15:21
 * @LastEditTime: 2021-12-10 16:19:39
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/api/Login/index.js
 */
import httpService from "@/request";

// 登录
export function login(params) {
  return httpService({
    url: `/login`,
    method: "post",
    data: params,
  });
}

// 登录验证码
export function registSms(params) {
  return httpService({
    url: `/login/regist/sms`,
    method: "post",
    data: params,
  });
}

// 账号注册
export function regist(params) {
  return httpService({
    url: `/login/regist`,
    method: "post",
    data: params,
  });
}
// 企业微信身份授权
export function wxAuthor(params) {
  return httpService({
    url: `/login/qywx/bind`,
    method: "post",
    data: params,
  });
}
