<!--
 * @Author: your name
 * @Date: 2021-12-10 10:07:12
 * @LastEditTime: 2021-12-10 16:08:56
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/views/Login/registerStepOne.vue
-->
<template>
    <div class="rg-s1-inner">
        <div class="rg-s1-t1">
            注册
        </div>
        <div class="rg-s1-t2">
            1.填写注册手机号码
        </div>
        <div class="rg-form">
            <a-form-model ref="rgForm" :model="form">
                <a-form-model-item label="手机号码" prop="phone" :rules="validate({name: 'phone', msg: '请输入手机号码'})">
                    <a-input-group compact>
                        <a-input size="large" allowClear style="width: 71%" v-model="form.phone" placeholder="手机号码"></a-input>
                        <a-button size="large" style="width: 29%" :disabled="inDis" @click="sendCode">{{txt}}</a-button>
                    </a-input-group>
                </a-form-model-item>
                <a-form-model-item label="验证码" prop="code" :rules="validate({name: 'value', msg: '请输入验证码'})">
                    <a-input size="large" allowClear v-model="form.code" placeholder="验证码"></a-input>
                </a-form-model-item>
            </a-form-model>
            <div style="margin-top: 60px;margin-bottom: 20px">
                <a-button size="large" style="width: 100%" type="primary" @click="next">下一步</a-button>
            </div>
            <span class="rg-st" @click="backLogin">已有账号，直接登录></span>
        </div>

    </div>
</template>

<script>
import { registSms } from "@/api/Login";
export default {
    data() {
        return {
            txt: "发送验证码",
            inDis: false,
            form: {
                phone: "",
                code: "",
            },
        };
    },
    methods: {
        backLogin() {
            this.setLoginStep(0);
        },
        // 下一步
        next() {
            this.$refs.rgForm.validate((valid) => {
                if (valid) {
                    this.setLoginStep(2);
                    this.$emit("next", this.form);
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
        // 发送验证码
        sendCode() {
            this.$refs.rgForm.validateField("phone", (valid) => {
                if (valid) {
                    console.log("手机号码有误");
                    return false;
                } else {
                    registSms({ phone: this.form.phone }).then(() => {
                        this.countdown(60);
                    });
                }
            });
        },
        // 倒计时
        countdown(time) {
            this.inDis = true;
            if (time == 0) {
                this.txt = "发送验证码";
                this.inDis = false;
                return;
            }
            this.txt = time;
            self.setTimeout(() => {
                time--;
                this.countdown(time);
            }, 1000);
        },
    },
};
</script>

<style lang="less" scoped>
.rg-s1-inner {
    position: relative;
    width: 700px;
    margin-left: 175px;
    margin-top: 100px;
}
.rg-form {
    width: 400px;
    margin-top: 50px;
}
.rg-s1-t1 {
    font-size: 32px;
    font-weight: bold;
    color: #333333;
}
.rg-s1-t2 {
    font-size: 28px;
    font-weight: 500;
    color: #333333;
}
.rg-st {
    font-size: 16px;
    cursor: pointer;
}
</style>
